import { request } from './shared';

export function searchInsurancePlansByPartDCode(partDCode) {
  return request({
    method: 'GET',
    params: {
      part_d_code: partDCode, // The API expects a parameter named "part_d_code" (or similar, adjust as necessary)
    },
    silent: true,
    url: '/search-insurance-plans-by-partd-code',
  });
}
