import { Select, Tooltip } from 'antd';
import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const groupDrugsByTier = (drugs) => {
  console.log('Grouping drugs by tier:', drugs);
  const tierGroups = {};

  drugs.forEach(drug => {
    if (!drug.tier_level_value) {
      console.warn(`Drug ${drug.proprietaryname} is missing tier_level_value`);
      return;
    }

    const tier = drug.tier_level_value;
    if (!tierGroups[tier]) {
      tierGroups[tier] = [];
    }
    tierGroups[tier].push(drug);
  });

  return tierGroups;
};

export const groupDrugsByNonproprietaryName = (drugs) => {
  console.log('Grouping drugs by nonproprietary name:', drugs);
  const groups = {};
  let nullNonproprietaryNameIndex = 0;

  drugs.forEach(drug => {
    if (drug.tier_level_value === undefined) {
      return;
    }

    // Generate a unique key for the drug
    const key = drug.nonproprietaryname === null ? `null-${nullNonproprietaryNameIndex++}` : drug.nonproprietaryname;

    if (!groups[key]) {
      groups[key] = {
        ...drug,
        uniqueId: `${drug.nonproprietaryname}-${drug.tier_level_value}`, // Ensure unique ID here
        proprietarynames: [drug.proprietaryname],
        details: [drug]
      };
    } else {
      groups[key].proprietarynames.push(drug.proprietaryname);
      groups[key].details.push(drug);
    }
  });

  return Object.values(groups);
};

export const sortByTier = (groups, sortOrder) => {
  console.log('Sorting by tier:', { groups, sortOrder });
  return groups.sort((a, b) => {
    let tierA = a.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(a.tier_level_value, 10);
    let tierB = b.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(b.tier_level_value, 10);

    let indexA = sortOrder.indexOf(tierA);
    let indexB = sortOrder.indexOf(tierB);

    if (indexA === -1) indexA = sortOrder.length;
    if (indexB === -1) indexB = sortOrder.length;

    return indexA - indexB;
  });
};

export const getCustomTierSortOrder = (hasTierSix) => {
  console.log('Determining custom tier sort order. Has tier 6:', hasTierSix);
  return hasTierSix ? [1, 2, 6, 3, 4, 5, 'Not Covered'] : [1, 2, 3, 4, 5, 6, 'Not Covered'];
};


export const getProprietaryNameExpandedRows = (nonproprietaryname, groupedResults) => {
  console.log('Getting proprietary name expanded rows for:', nonproprietaryname);
  const group = groupedResults.find(group => group.nonproprietaryname === nonproprietaryname);
  return group ? group.details : [];
};


export const proprietaryNameDetailColumns = () => [
  {
    dataIndex: 'proprietaryname',
    title: 'Description',
  },
  {
    dataIndex: 'tier_level_value',
    title: 'Tier Level Value',
    render: (tierLevelValue) => {
      return (
        <span>
          {tierLevelValue}
        </span>
      );
    },
  },
];

const formatCost = (costType, amount) => {
  if (amount === undefined || amount === null || amount === 'N/A') {
    return 'N/A';
  }

  if (costType === 0) {
    if (typeof amount === 'string' && amount.includes('%')) {
      return amount;
    }
    return `$${parseFloat(amount).toFixed(2)}`;
  }

  if (costType === 2 && parseFloat(amount) === 0.25) {
    return '25%';
  }

  if (costType === 1) {
    return `$${parseFloat(amount).toFixed(2)}`;
  }

  if (costType === 2) {
    return `${(parseFloat(amount) * 100).toFixed(0)}%`;
  }

  return amount;
};

export const renderRequirements = (drug, selectedInsurancePlan, handlePopupOpen) => {
  console.log('Rendering requirements for drug:', drug);
  const requirements = Array.isArray(drug.requirements) ? drug.requirements : [drug.requirements];
  if (drug.STEP_THERAPY_YN === 'Y') {
    requirements.push('Step Therapy required');
  }

  const quantityDetails = requirements.find(req => req.startsWith('Quantity Limit Amount')) || '';
  const formattedQuantityDetails = quantityDetails ? quantityDetails.replace('Quantity Limit Amount: ', '').replace(', Quantity Limit Days: ', ' per ') : '';

  return (
    <div>
      {requirements.map((requirement, index) => {
        if (requirement === 'Prior Authorization required') {
          if (selectedInsurancePlan === 24039 || selectedInsurancePlan === 24048) {
            return (
              <div key={index}>
                {requirement}
                <a
                  href="https://selecthealth.promptpa.com/MemberHome.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '5px', color: 'blue' }}
                >
                  (Start PA)
                </a>
              </div>
            );
          } else {
            return (
              <div key={index}>
                {requirement}
                <span onClick={() => {
                  const popupContent = 'Prior Authorization Function is Working-in-progress';
                  handlePopupOpen(drug.proprietaryname, popupContent);
                }} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue' }}>
                  (Check)
                </span>
              </div>
            );
          }
        } else if (requirement === 'Quantity Limits apply') {
          return (
            <div key={index}>
              QL:{formattedQuantityDetails} days
            </div>
          );
        } else if (requirement.startsWith('Quantity Limit Amount')) {
          return null;
        } else {
          return <div key={index}>{requirement}</div>;
        }
      })}
    </div>
  );
};



export const resultTableColumns = (selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails) => {
  console.log('Generating result table columns with:', { selectedDaysSupply, selectedPharmacyType, selectedInsurancePlan, selectedPlanDetails });
  const tierLevelDescriptions = {
    1: 'Preferred Generic Drug',
    2: 'Generic Drug',
    3: 'Preferred Brand Drug',
    4: 'Non-Preferred Drug',
    5: 'Specialty Tier Drug',
    6: 'Select Care Drug',
  };

  const tierTooltipContent = (
    <div>
      <p>Tier 1: {tierLevelDescriptions[1]}</p>
      <p>Tier 2: {tierLevelDescriptions[2]}</p>
      <p>Tier 3: {tierLevelDescriptions[3]}</p>
      <p>Tier 4: {tierLevelDescriptions[4]}</p>
      <p>Tier 5: {tierLevelDescriptions[5]}</p>
      <p>Tier 6: {tierLevelDescriptions[6]}</p>
    </div>
  );

  const patientOutOfPocketCostRender = (record, selectedDaysSupply, selectedPharmacyType, deductible) => {
    if (!Array.isArray(record.out_of_pocket_cost)) {
      return 'No cost data available';
    }

    const daysSupplyValue = selectedDaysSupply === '30days' ? 1 : 2;
    const isInsulin = record.out_of_pocket_cost.some(cost => cost.coverage_level === undefined);
    let costHeaderSuffix = selectedPharmacyType === 'retail' ? 'Pharmacy' : 'Mail Pharmacy';

    const costHeader = `Preferred ${costHeaderSuffix} Cost / ${selectedDaysSupply.replace('days', ' Days')}`;

    const mailOrderNotOffered = record.out_of_pocket_cost.some(cost =>
      cost.days_supply === daysSupplyValue &&
      cost.cost_amt_mail_pref === 'not offered' &&
      cost.cost_amt_mail_nonpref === 'not offered'
    );

    if (mailOrderNotOffered && selectedPharmacyType === 'mail') {
      return <p>The plan doesn’t offer a mail order benefit.</p>;
    }

    if (deductible > 0 && !record.out_of_pocket_cost.some(cost => cost.coverage_level === 0 && cost.days_supply === daysSupplyValue)) {
      const deductibleStageEntry = {
        coverage_level: 0,
        days_supply: daysSupplyValue,
        cost_type_pref: 2, // This could be a special indicator for deductible stage.
        cost_type_nonpref: 2, // Assuming the same applies for non-preferred.
        cost_type_mail_pref: 2, // Assuming the same applies for mail preferred.
        cost_type_mail_nonpref: 2, // Assuming the same applies for mail non-preferred.
        cost_amt_pref: '1.00', // 100% cost to patient during the deductible stage.
        cost_amt_nonpref: '1.00', // Assuming 100% for non-preferred as well.
        cost_amt_mail_pref: '1.00', // Assuming 100% for mail order preferred.
        cost_amt_mail_nonpref: '1.00' // Assuming 100% for mail order non-preferred.
      };
      record.out_of_pocket_cost.unshift(deductibleStageEntry);
    }

    // If the drug is insulin, process using insulin-specific logic
    if (isInsulin) {
      return processAndDisplayInsulinCostNew(record.out_of_pocket_cost, selectedDaysSupply, selectedPharmacyType);
    }
    
    const hasCoverageGap = record.out_of_pocket_cost.some(cost => cost.coverage_level === 2 && cost.days_supply === daysSupplyValue);
    if (!hasCoverageGap) {
      const coverageGapEntry = {
        coverage_level: 2,
        days_supply: daysSupplyValue,
        cost_type_pref: 2, 
        cost_type_nonpref: 2, 
        cost_type_mail_pref: 2, 
        cost_type_mail_nonpref: 2, 
        cost_amt_pref: 0.25, 
        cost_amt_nonpref: 0.25,
        cost_amt_mail_pref: 0.25,
        cost_amt_mail_nonpref: 0.25
      };
      record.out_of_pocket_cost.push(coverageGapEntry);
    }
    
    // Filter costs based on days supply and coverage level, assuming the array might not be sorted by coverage level
    const sortedAndFilteredCosts = record.out_of_pocket_cost
      .filter(cost => cost.days_supply === daysSupplyValue)
      .sort((a, b) => a.coverage_level - b.coverage_level);

    // If there's no data after filtering, return a message or a default UI element
    if (sortedAndFilteredCosts.length === 0) {
      return 'No cost data available for the selected options';
    }

    const tableRows = sortedAndFilteredCosts.map((cost, index) => {
      const coverageWord = getCoverageLevelWord(cost.coverage_level);
      let pharmacyKey;
      if (cost.coverage_level === 0) {
        pharmacyKey = 'cost_amt_pref'; // Deductible stage always uses preferred cost
      } else {
        pharmacyKey = selectedPharmacyType === 'retail' ? 'cost_amt_pref' : 'cost_amt_mail_pref';
      }
      const mailOrderNotOffered = (pharmacyKey.includes('mail') && 
                                    cost.cost_amt_mail_pref === 'not offered' &&
                                    cost.cost_amt_mail_nonpref === 'not offered');

      let costFormatted;
      if (mailOrderNotOffered) {
        costFormatted = "The plan doesn’t offer a mail order benefit";
      } else {
        costFormatted = formatCost(cost.cost_type_pref, cost[pharmacyKey]);
      }

      return (
        <tr key={index}>
          <td>{coverageWord}</td>
          <td>{costFormatted}</td>
        </tr>
      );
    });

    return (
      <table>
        <thead>
          <tr>
            <th>Coverage Level</th>
            <th>{costHeader}</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  return [
    {
      dataIndex: 'proprietaryname',
      title: 'Drug Name',
      render(value, record) {
        const displayValue = record.nonproprietaryname || value; // Use nonproprietaryname if available, otherwise proprietaryname
        return (
          <div>
            {record.nonproprietaryname
              ? <strong>{displayValue}</strong> // Bold if nonproprietaryname is available
              : displayValue // Normal text if nonproprietaryname is missing
            }
          </div>
        );
      },
    },
    {
      dataIndex: 'tier_level_value',
      title: (
        <Tooltip placement="bottom" title={tierTooltipContent}>
          Tier Level Value <QuestionCircleOutlined />
        </Tooltip>
      ),
      render: (tierLevelValue) => {
        // Return only 'Not Covered' if the tier level is not available, without 'Unknown Tier'
        if (tierLevelValue === 'Not Covered') return 'Not Covered';
        
        const description = tierLevelDescriptions[tierLevelValue];
        return description ? `${tierLevelValue} (${description})` : tierLevelValue;
      },
    },
    {
      dataIndex: 'out_of_pocket_cost',
      title: (
        <div>
          Patient Out-of-pocket ($)
          <div style={{ fontSize: 'smaller', color: '#888' }}>
            (% is the percentage of the drug list price)
          </div>
        </div>
      ),
      render: (_, record) => patientOutOfPocketCostRender(
        record, 
        selectedDaysSupply, 
        selectedPharmacyType, 
        selectedPlanDetails ? selectedPlanDetails.deductible : 0 // Safely access deductible or default to 0
      ),
    },
    {
      dataIndex: 'active_ingred_unit',
      title: 'Coverage Requirements',
      render: (_, drug) => {
        return drug.covered ? renderRequirements(drug, selectedInsurancePlan, handlePopupOpen) : '';
      },
    },
  ];
};

export const getCoverageLevelWord = (level) => {
  const coverageLevels = {
    0: '0. Pre-Deductible',
    1: '1. Initial Coverage',
    2: '2. Coverage Gap',
    3: '3. Catastrophic',
  };
  return coverageLevels[level] || 'Unknown';
};

export const SupplyDurationSelector = ({ value, onChange }) => {
  console.log('Rendering SupplyDurationSelector with value:', value);
  return (
    <Select value={value} style={{ width: 120 }} onChange={onChange}>
      <Select.Option value="30days">30 Days</Select.Option>
      <Select.Option value="90days">90 Days</Select.Option>
    </Select>
  );
};

export const PharmacyTypeSelector = ({ value, onChange }) => {
  console.log('Rendering PharmacyTypeSelector with value:', value);
  return (
    <Select value={value} style={{ width: 160 }} onChange={onChange}>
      <Select.Option value="retail">Retail Pharmacy</Select.Option>
      <Select.Option value="mail">Mail Pharmacy</Select.Option>
    </Select>
  );
};

const processAndDisplayInsulinCostNew = (insulinCostData, daysSupplyFilter, pharmacyType) => {
  console.log('Processing and displaying insulin cost for:', { insulinCostData, daysSupplyFilter, pharmacyType });
  if (!Array.isArray(insulinCostData)) {
    return 'No insulin cost data available';
  }

  const daysSupplyValue = daysSupplyFilter === '30days' ? 1 : (daysSupplyFilter === '90days' ? 2 : 1);
  const coverageLevels = {
    0: '0. Deductible Stage',
    1: '1. Initial Coverage',
    2: '2. Coverage Gap',
    3: '3. Catastrophic',
  };

  let coverageInfo = [
    { level: '0', preferredCost: 'N/A', nonPreferredCost: 'N/A' },
    { level: '1', preferredCost: 'N/A', nonPreferredCost: 'N/A' },
    { level: '2', preferredCost: 'N/A', nonPreferredCost: 'N/A' },
    { level: '3', preferredCost: 0, nonPreferredCost: 0 },
  ];

  insulinCostData.forEach(cost => {
    if (cost.days_supply === daysSupplyValue) {
      let preferredCost, nonPreferredCost;
      if (pharmacyType === 'retail') {
        preferredCost = cost.copay_amt_pref_insln !== null ? cost.copay_amt_pref_insln : cost.copay_amt_nonpref_insln;
        nonPreferredCost = cost.copay_amt_nonpref_insln;
      } else {
        preferredCost = cost.copay_amt_mail_pref_insln !== null ? cost.copay_amt_mail_pref_insln : cost.copay_amt_mail_nonpref_insln;
        nonPreferredCost = cost.copay_amt_mail_nonpref_insln;
      }

      preferredCost = (preferredCost !== null && preferredCost !== undefined) ? preferredCost.toString() : 'N/A';
      nonPreferredCost = (nonPreferredCost !== null && nonPreferredCost !== undefined) ? nonPreferredCost.toString() : 'N/A';
      
      coverageInfo[0].preferredCost = preferredCost;
      coverageInfo[0].nonPreferredCost = nonPreferredCost;
      coverageInfo[1].preferredCost = preferredCost;
      coverageInfo[1].nonPreferredCost = nonPreferredCost;
      coverageInfo[2].preferredCost = preferredCost;
      coverageInfo[2].nonPreferredCost = nonPreferredCost;
    }
  });

  if (coverageInfo[1].preferredCost === 'N/A' && coverageInfo[2].preferredCost === 'N/A') {
    coverageInfo[3].preferredCost = 'N/A';
  }
  if (coverageInfo[1].nonPreferredCost === 'N/A' && coverageInfo[2].nonPreferredCost === 'N/A') {
    coverageInfo[3].nonPreferredCost = 'N/A';
  }

  const tableRows = coverageInfo.map((info, index) => {
    const coverageWord = coverageLevels[info.level];
    const formattedPreferredCost = info.preferredCost !== 'N/A' ? `$${parseFloat(info.preferredCost).toFixed(2)}` : 'N/A';
    
    return (
      <tr key={index}>
        <td>{coverageWord}</td>
        <td>{formattedPreferredCost}</td>
      </tr>
    );
  });

  return (
    <table>
      <thead>
        <tr>
          <th>Coverage Level</th>
          <th>Preferred Pharmacy Cost</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
