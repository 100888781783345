import { message } from 'antd';
import axios from 'axios';
import { useRef } from 'react';

const never = new Promise(() => {
  // this promise will never resolve
});

/**
 * receives the same options as axios function.
 * in addition, set `options.silent` to `true` if
 * the api doesn't need to pop up the loading message
 */
export function request(options) {
  const source = axios.CancelToken.source();
  const { silent, ...opts } = options;
  opts.cancelToken = source.token;
  return [
    axios(opts).then(
      (response) => {
        return response.data;
      },
      (ex) => {
        if (axios.isCancel(ex)) {
          console.warn(ex.message);
          return never;
        }
        throw ex;
      }
    ),
    source,
    silent,
  ];
}

export function transformDataToForm(data) {
  const form = new FormData();
  for (const key of Object.keys(data)) {
    appendFormDataItem(form, key, data[key]);
  }
  return form;
}

function appendFormDataItem(form, name, value) {
  if (Array.isArray(value)) {
    for (const entry of value) {
      appendFormDataItem(form, name + '[]', entry);
    }
  } else {
    form.append(name, value);
  }
}

/**
 * 
 * @param {Function} factory api
 * @param {Boolean} silent automatically show loading message if not set to a truthy value
 * @returns {Promise<unknown>}
 */
export function useAPI(factory, silent) {
  const ref = useRef();

  return async (...args) => {
    if (ref.value) {
      ref.value();
    }
    const [promise, source, silentDefaultValue] = factory(...args);
    const closeMsg = (silent === undefined ? silentDefaultValue : silent) ? undefined : message.loading('Loading, please wait...', 0);
    const abortAll = () => {
      source.cancel();
      if (closeMsg) {
        closeMsg();
      }
    };
    ref.value = abortAll;
    let result;
    let error;
    try {
      result = await promise;
    } catch (ex) {
      error = ex;
    }
    if (ref.value !== abortAll) {
      return never;
    }
    abortAll();
    ref.value = undefined;
    if (error) {
      const content = error.response?.data?.error || 'An unexpected error occurred.';
      if (silent) {
        throw Error(content);
      }
      message.error(content);
      return never;
    }
    return result;
  }
}
