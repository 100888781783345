import React from 'react';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import { Menu } from 'antd';
import './index.css';

export default function App(props) {
  const routes = [];
  const menuItems = [];
  for (const { path, element, menuIcon, menuLabel } of props.routes) {
    routes.push(<Route path={path} element={element} key={path} />);
    if (menuIcon && menuLabel) {
      menuItems.push({
        key: path,
        label: <Link to={path}>{menuLabel}</Link>,
        icon: menuIcon,
      });
    }
  }

  return (
    <BrowserRouter>
      <div className="app">
        <header className="app-header">
          <div className="app-header-main">
            <h1 className="app-header-title">{props.title}</h1>
            <ul className="app-header-items">
              {props.headers.map((value, key) => <li className="app-header-item" children={value} key={key} />)}
            </ul>
          </div>
        </header>
        <div className="app-layout">
          <aside className="app-aside">
            <Menu
              mode="vertical"
              defaultSelectedKeys={[window.location.pathname]}
              items={menuItems}
            />
          </aside>
          <main className="app-main">
            <Routes>{routes}</Routes>
          </main>
        </div>
        <div className="app-footer">
          &copy; {new Date().getFullYear()} MedHug, Inc. All rights reserved.
        </div>
      </div>
    </BrowserRouter>
  );
}
