import React, { useState } from "react";
import {
  Card,
  Button,
  Tooltip,
  Collapse,
  Modal,
  Input,
  Form,
  notification,
} from "antd";
import {
  CopyOutlined,
  MailOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";

const { Panel } = Collapse;

const customExpandIcon = (props) => {
  const { isActive } = props;
  return (
    <CaretRightOutlined
      rotate={isActive ? 90 : 0}
      style={{
        fontSize: "16px",
        color: "#000",
        transition: "transform 0.3s ease",
      }}
    />
  );
};

const DrugCoverageSummary = ({ planDetails, drugResults }) => {
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const formatRequirements = (requirements) => {
    if (!requirements || requirements.length === 0) {
      return "No specific requirements";
    }
    let formatted = Array.isArray(requirements)
      ? requirements.join(", ")
      : requirements;
    formatted = formatted.replace(
      "Prior Authorization required",
      "PA Required"
    );
    formatted = formatted.replace("Quantity Limits apply", "QL Apply");
    formatted = formatted.replace(
      /Quantity Limit Amount: \d+, Quantity Limit Days: \d+/,
      ""
    );
    return formatted.replace(/,?\s*$/, "");
  };

  const groupDrugsByTier = () => {
    const tierMap = {};
    const notCoveredDrugs = new Set();

    if (!Array.isArray(drugResults)) {
      return { covered: tierMap, notCovered: Array.from(notCoveredDrugs) };
    }

    drugResults.forEach((drug) => {
      if (!drug.tier_level_value) {
        console.warn(`Drug ${drug.proprietaryname} is missing tier_level_value`);
        return;
      }
      const tierKey = `Tier ${drug.tier_level_value}`;
      if (drug.covered) {
        if (!tierMap[tierKey]) {
          tierMap[tierKey] = {
            drugs: [],
            requirements: formatRequirements(drug.requirements),
          };
        }
        if (!tierMap[tierKey].drugs.includes(drug.nonproprietaryname)) {
          tierMap[tierKey].drugs.push(drug.nonproprietaryname);
        }
      } else {
        notCoveredDrugs.add(drug.nonproprietaryname);
      }
    });

    return { covered: tierMap, notCovered: Array.from(notCoveredDrugs) };
  };

  const { covered, notCovered } = groupDrugsByTier();

  const renderCoveredDrugs = () => {
    return Object.entries(covered).map(([tier, details], index) => (
      <p key={index} className="summary-text">
        <strong>{details.drugs.join(", ")}</strong> are <strong>covered</strong>{" "}
        in {tier} ({details.requirements}).
      </p>
    ));
  };

  const renderNotCoveredDrugs = () => (
    <p className="summary-text">
      <strong>{notCovered.join(", ")}</strong> are <strong>not covered</strong>.
    </p>
  );

  const deductibleText =
    planDetails && planDetails.deductible > 0
      ? `has an annual deductible of $${planDetails.deductible}`
      : "has no deductible";

  const copyToClipboard = () => {
    const summaryTextElements = document.querySelectorAll(
      "#drug-coverage-summary-text .summary-text"
    );
    const summaryText = Array.from(summaryTextElements)
      .map((element) => element.innerText)
      .join("\n");

    navigator.clipboard.writeText(summaryText).then(
      () => notification.success({ message: "Copied to clipboard!" }),
      (error) =>
        notification.error({
          message: "Copy failed!",
          description: error.message,
        })
    );
  };

  const sendEmail = async () => {
    const summaryTextElements = document.querySelectorAll(
      "#drug-coverage-summary-text .summary-text"
    );
    const summaryText = Array.from(summaryTextElements)
      .map((element) => element.textContent)
      .join("\n\n");

    const formattedText = `${summaryText}\n\n\n\nMedHug.`; // Add your MedHug signature line

    const messageDetails = {
      subject: "Drug Coverage Summary",
      body: formattedText,
      to: emailAddress,
    };

    try {
      const response = await fetch("/send-summary-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageDetails),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Email notification sent successfully:", data);
        notification.success({
          message: "Email Sent",
          description: `The drug coverage summary has been sent to ${emailAddress}.`,
        });
      } else {
        throw new Error(data.message || "Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email notification:", error);
      notification.error({
        message: "Error",
        description: "Failed to send email due to a network or server issue.",
      });
    }
  };

  const showEmailModal = () => {
    setEmailModalVisible(true);
  };

  const onEmailChange = (e) => {
    setEmailAddress(e.target.value);
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={[]}
      expandIcon={customExpandIcon}
      className="site-collapse-custom-collapse"
    >
      <Panel
        header={
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Drug Coverage Overview
          </span>
        }
        key="1"
        className="site-collapse-custom-panel"
        style={{
          backgroundColor: "#f7f7f7",
          border: "none",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Card bordered={false} id="drug-coverage-summary-text">
          <p className="summary-text">
            <strong>The plan is {planDetails ? planDetails.plan_name : 'Unknown Plan'}</strong>, {deductibleText}.
          </p>
          {renderCoveredDrugs()}
          {renderNotCoveredDrugs()}
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Tooltip title="Copy this summary to your clipboard">
              <Button
                onClick={copyToClipboard}
                icon={<CopyOutlined />}
                style={{ marginRight: "10px" }}
              >
                Copy
              </Button>
            </Tooltip>
            <Tooltip title="Send this summary via email">
              <Button
                onClick={showEmailModal}
                type="primary"
                icon={<MailOutlined />}
              >
                Email
              </Button>
            </Tooltip>
          </div>
          <Modal
            title="Send Email"
            visible={emailModalVisible}
            onOk={sendEmail}
            onCancel={() => setEmailModalVisible(false)}
            okText="Send"
            cancelText="Cancel"
          >
            <Form layout="vertical">
              <Form.Item
                label="Email Address"
                required
                tooltip="Enter your email address to receive the summary"
              >
                <Input
                  placeholder="Enter email"
                  value={emailAddress}
                  onChange={onEmailChange}
                />
              </Form.Item>
            </Form>
          </Modal>
        </Card>
      </Panel>
    </Collapse>
  );
};

export default DrugCoverageSummary;
