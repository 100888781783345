import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

export default function PAPPortalPage() {
  // Function to handle file upload - for now, it will just log to the console
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log('File uploaded:', file.name);
    // Here you would handle the file upload logic, e.g., sending it to a server
  };

  return (
    <div className="pap-portal-container">
      <h1>Patient Assistance Program Application Portal</h1>
      
      <div className="upload-section">
        <label className="upload-label">
          Application form
          <input type="file" onChange={handleFileUpload} />
        </label>
        <label className="upload-label">
          Patient income proof
          <input type="file" onChange={handleFileUpload} />
        </label>
        <label className="upload-label">
          Prescriptions
          <input type="file" onChange={handleFileUpload} />
        </label>
      </div>

      <div className="tracking-process">
        <h2>Tracking process</h2>
        <p>The patient is eligible for Novo Nordisk Patient Assistance Program.</p>
        <p>The application has been sent to NovoCare.</p>
        <p>Enrollment has been confirmed.</p>
        <p>Medication will be sending to provider’s office on 10/29/2023. <Link to="/tracking">(Tracking Number)</Link></p>
        <p>The patient have been contacted to pick up the medications on 10/30/2023 at 4pm.</p>
      </div>
    </div>
  );
}
