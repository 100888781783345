import { request, transformDataToForm } from './shared';

export function searchDrugInfo(county, drug, plan) {
  return request({
    data: transformDataToForm({
      county_name: county,
      drug_name: drug,
      plan_name: plan,
    }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: '/search-drug-info',
  });
}
