// src/api/searchDrugClassInfoByMultiplePartD.js
import { request } from './shared';

export function searchDrugClassInfoByMultiplePartD(drugClass, selectedPlans) {
  return request({
    data: {
      drug_class: drugClass,
      plans: selectedPlans,
    },
    method: 'POST',
    url: '/search-drug-class-info-by-multiple-partd-codes',
  });
}
