import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, DatePicker, Flex, Form, Input, InputNumber } from 'antd';

export function getSearchParamsNameFromLocation(location) {
  return new URLSearchParams(location.search.substring(1)).get('name');
}

export default function PatientAssistanceProgram() {
  const location = useLocation();
  const [name, setName] = useState(getSearchParamsNameFromLocation(location));
  const [formData] = useState({
    name: '',
    dateOfBirth: '',
    Medication: '',
    householdIncome: 1000,
    householdNumber: 2
  });

  useEffect(() => {
    setName(getSearchParamsNameFromLocation(location));
  }, [location]);

  const handleSubmit = (values) => {
    console.log(values);
    // https://ant.design/components/form
    console.log({
      ...values,
      dateOfBirth: values.dateOfBirth.format('YYYY-MM-DD'),
    });
    // navigate('/pap-portal');
  };

  return (

    <Flex gap="middle" align="center" vertical={true}>
      <h2 style={{color: '#1f1f1f'}}>Patient Assistance Program Application Portal {name}</h2>
      <Form
        layout="vertical"
        style={{ width: 600 }}
        initialValues={formData}
        onFinish={handleSubmit}
      >

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Date of Birth"
          name="dateOfBirth"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Medication"
          name="Medication"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Household income"
          name="householdIncome"  
        >
          <InputNumber
            addonBefore="$"
            style={{ width: '100%' }}
            min={0} 
          />
        </Form.Item>

        <Form.Item
          label="Number of people in the household"
          name="householdNumber"  
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0} 
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block={true}>Check and apply</Button>
        </Form.Item>
      </Form>
    </Flex>
  );
}
