import './index.css';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Select, message, Modal, Input, Radio, Alert } from 'antd';
import React, { useState, useEffect } from 'react';
import { api, useAPI } from '../../api';
import DrugClassResultsTableNew from '../../components/DrugClassResultsTableNew';
// import { debounce } from 'lodash';
import FormSuggestInput from '../../components/FormSuggestInput';
// import ContactModal from '../../components/ContactModal';
import StatusSelect from './StatusInput';
const SEARCH_METHOD = {
  BY_PARTD: 'byPartD',
  BY_COUNTY_INSURANCE: 'byCountyInsurance',
};


const SEARCH_FORM_FIELDS = {
    PartDCode: 'partDCode',
    DrugClass: 'drugClass',
    SelectedPlan: 'selectedPlan',
    CountyCode: 'countyCode',
    Plan: 'plan',
};

const drugClassSelectOptions = [
  {
    label: 'GLP-1 Agonists / GIP GLP-1 Agonists',
    value: 'GLP-1 Receptor Agonist',
  },
  {
    label: 'SGLT2 Inhibitors',
    value: 'Sodium-Glucose Cotransporter 2 Inhibitor',
  },
  {
    label: 'Insulin',
    value: 'insulin',
  },
  {
    label: 'Biguanides',
    value: 'Biguanides',
  },
  {
    label: 'Sulfonylureas',
    value: 'Sulfonylurea Compounds',
  },

  {
    label: 'DPP-4 Inhibitors',
    value: 'Dipeptidyl Peptidase 4 Inhibitor',
  },
  {
    label: 'Thiazolidinediones',
    value: 'Thiazolidinediones',
  },
];

export default function DrugClassSearch() {
    const [form] = Form.useForm();
    const searchInsurancePlansByPartDCode = useAPI(api.searchInsurancePlansByPartDCode, true); 
    const searchDrugClassInfoByPartDCode = useAPI(api.searchDrugClassInfoByPartDCode);
    const sendContact = useAPI(api.sendContactInfo); 
    const getInsurancePlans = useAPI(api.getInsurancePlans);
    const [planOptions, setPlanOptions] = useState([]);
    const [results, setResults] = useState([]);
    const [, setSelectedPlan] = useState('');
    const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
    const [expandedRow, setExpandedRow] = useState(null);
    const [deductible, setDeductible] = useState('');
    // const [isModalVisible, setIsModalVisible] = useState(false);
    const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false);
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);
    
    const [searchMethod, setSearchMethod] = useState(SEARCH_METHOD.BY_PARTD);
    const searchDrugClassInfo = useAPI(api.searchDrugClassInfo);
    const [selectedDrugClass, setSelectedDrugClass] = useState('');
    const [showInsulinAlert, setShowInsulinAlert] = useState(false);
    useEffect(() => {
      const hasInsulin = results.some(drug => drug.classification === 'insulin');
      setShowInsulinAlert(hasInsulin);
  }, [results]);

    const handleDrugClassChange = value => {
        setSelectedDrugClass(value);
        form.setFieldValue(SEARCH_FORM_FIELDS.DrugClass, value);
    };
  async function planOptionsDepsChangeCallback() {
    const countyCode = form.getFieldValue(SEARCH_FORM_FIELDS.CountyCode);
    console.log('County Code from form:', countyCode);

    if (!countyCode) {
      setPlanOptions([]);
      message.error('Please enter a county name.');
      return;
    }
    const includeMA = true;
    const includePDP = true;
    
    if (!(includeMA || includePDP)) {
      setPlanOptions([]); // Reset plan options if no plan types are selected
      return;
    }
  
    try {
      const response = await getInsurancePlans(countyCode, includeMA, includePDP);
      if (response && Array.isArray(response.plans)) {
        const uniquePlans = new Map();
        response.plans.forEach(plan => {
          console.log(plan);
          const compositeKey = `${plan.formulary_id}-${plan.plan_id}`;
          uniquePlans.set(compositeKey, plan);
        });
  
        const sortedPlanOptions = Array.from(uniquePlans.values())
          .sort((a, b) => a.plan_name.localeCompare(b.plan_name))
          .map(plan => ({
            label: `${plan.plan_name} (Formulary: ${plan.formulary_id}) - ${plan.contract_name}`,
            value: `${plan.formulary_id}-${plan.plan_id}`, // Ensure this composite key is unique
            planDetails: {
              formulary_id: plan.formulary_id,
              contract_id: plan.contract_id,
              plan_id: plan.plan_id,
              segment_id: plan.segment_id,
              deductible: plan.deductible
            }
          }));
        setPlanOptions(sortedPlanOptions);
      }
    } catch (ex) {
      console.error('Error fetching insurance plans:', ex);
      message.error('Error fetching insurance plans: ' + (ex.message || ex));
    }
  }
  
  useEffect(() => {
    const plan = form.getFieldValue(SEARCH_FORM_FIELDS.Plan);
    if (plan && planOptions.find(option => option.value === plan) === undefined) {
      form.setFieldValue(SEARCH_FORM_FIELDS.Plan, '');
    }
  }, [planOptions, form]);

    const handleFormSubmit = async (values) => {
      const drugClass = form.getFieldValue(SEARCH_FORM_FIELDS.DrugClass);

      if (searchMethod === SEARCH_METHOD.BY_PARTD) {

        const formulary_id = selectedPlanDetails.formulary_id || '';
        const contract_id = selectedPlanDetails.contract_id || ''; 
        const plan_id = selectedPlanDetails.plan_id || '';         
        const segment_id = selectedPlanDetails.segment_id || '';   
        
        console.log(selectedPlanDetails); 

        if (!formulary_id) {
          console.error('Formulary ID is missing.');
          message.error('Please select a plan before searching.');
          return;
        }

        try {
          const data = await searchDrugClassInfoByPartDCode(drugClass, formulary_id, contract_id, plan_id, segment_id);
          const updatedResults = data.drug_results.map(drug => ({ ...drug }));
      
          setResults(updatedResults);
          setSelectedPlan(formulary_id);
        } catch (error) {
          console.error('Error fetching drug class info by PartD Code:', error);
          message.error('An error occurred while fetching the drug class info.');
        }
      } else if (searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE) {
        const countyCode = form.getFieldValue(SEARCH_FORM_FIELDS.CountyCode);
        const {
          formulary_id,
          contract_id,
          plan_id,
          segment_id
        } = selectedPlanDetails;
        
        console.log('Segment ID on form submit:', segment_id); 
    
        if (!countyCode) {
          console.error('County Code is missing.');
          message.error('Please enter a county name.');
          return;
        }
    
        if (!formulary_id) {
          console.error('Formulary ID is missing.');
          message.error('Please select an insurance plan.');
          return;
        }
    
        try {
          const data = await searchDrugClassInfo(countyCode, drugClass, formulary_id, contract_id, plan_id, segment_id);
          const updatedResults = data.drug_results.map(drug => ({ ...drug }));
      
          setResults(updatedResults);
          setSelectedPlan(formulary_id);
        } catch (error) {
          console.error('Error fetching drug class info by County and Insurance:', error);
          message.error('An error occurred while fetching the drug class info.');
        }
      }
    };
      
  const fetchPlansBasedOnPartDCode = async (partDCode) => {
      if (!partDCode || (partDCode.length !== 8 && partDCode.length !== 11)) {
          setPlanOptions([]);
          const error = new Error('PartD/Plan Benefits Code must be 8 or 11 characters long.');
          error.help = 'PartD/Plan Benefits Code must be 8 or 11 characters long.';
          throw error;
      }

      try {
        const response = await searchInsurancePlansByPartDCode(partDCode);
        if (response && response.formulary_id) { // Check for the existence of formulary_id or another unique field
          // Directly use the response object since it's not an array of plans
          setPlanOptions([{
            label: `${response.plan_name} - ${response.contract_name}`,
            value: response.formulary_id,
            planDetails: response // Storing the entire plan object for later use
          }]);
        } else {
          setPlanOptions([]);
          const error = new Error('No plan details found.');
          error.help = 'No plan details found.';
          throw error;
        }
      } catch (error) {
        console.error('Error:', error);
        const throwErr = new Error('No plan details found, please re-check your input.');
        throwErr.help = 'No plan details found, please re-check your input.';
        throw throwErr;
      }
    };
  const debouncedSearchPlans = fetchPlansBasedOnPartDCode;//debounce(fetchPlansBasedOnPartDCode, 800);
  
  useEffect(() => {
    const partDCode = form.getFieldValue(SEARCH_FORM_FIELDS.PartDCode);
    if (partDCode && (partDCode.length === 8 || partDCode.length === 11)) {
      debouncedSearchPlans(partDCode);
    }
  }, [form, debouncedSearchPlans]);

  const handlePlanChange = (value) => {
    console.log("Received value for handlePlanChange:", value);
    console.log("Type of passed value:", typeof value);
    console.log("Types in planOptions:", planOptions.map(opt => typeof opt.value));
    const selectedOption = planOptions.find(option => option.value === value);
    console.log("Selected option:", selectedOption);
    
    // Ensure selectedOption is not undefined before accessing its properties
    if (selectedOption && selectedOption.planDetails) {
      console.log('Selected plan details:', selectedOption.planDetails);
      setSelectedPlanDetails(selectedOption.planDetails);
      setDeductible(selectedOption.planDetails.deductible);
      form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: selectedOption.planDetails.formulary_id });
    } else {
      console.error('Selected plan details not found or no planDetails property.');
      setSelectedPlanDetails({});
      setDeductible(undefined);
      // Reset the PartDCode in the form state
      form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: '' });
      alert('Plan details are incomplete or missing.');
    }
  };
  
  const handleInstructionsModalCancel = () => {
    setIsInstructionsModalVisible(false);
  };

  const handleContactModalSubmitPartD = async (values) => {
    try {
      const response = await sendContact(values); 

      if (response && response.message) {
        message.success(response.message || 'Your request has been submitted. We will contact you shortly.', 3);  // Show success message with a green checkmark
        setIsContactModalVisible(false);
      } else {
        // Handle server errors
        message.error(response.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network errors
      console.error('There was an error submitting the form:', error);
      message.error('There was a network error. Please try again later.');
    }
  };
  
  const handleContactModalCancel = () => {
    setIsContactModalVisible(false);
  };

  const showContactModal = () => {
    setIsInstructionsModalVisible(false);
    setIsContactModalVisible(true);
  };

  const handleSearchMethodChange = (e) => {
    setSearchMethod(e.target.value);
    form.resetFields();
  };

 
      return (
        <>
          <Button
            onClick={showContactModal}
            className="floating-help-button"
            style={{
              marginBottom: 16,
              backgroundColor: '#f5f5f5', // This is a light gray color similar to the table header
              color: 'black', // The text color is black
              borderColor: '#f5f5f5' // The border color is the same as the background
            }}
          >
            <strong>Ask for Help</strong>
          </Button>
          <Radio.Group onChange={handleSearchMethodChange} value={searchMethod} style={{ marginBottom: 16 }}>
            <Radio.Button value={SEARCH_METHOD.BY_PARTD}>Search by Plan ID</Radio.Button>
            <Radio.Button value={SEARCH_METHOD.BY_COUNTY_INSURANCE}>Search by Insurance Name</Radio.Button>
          </Radio.Group>

          <Form form={form} layout="vertical" onFinish={handleFormSubmit} style={{ maxWidth: 800 }}>
          {searchMethod === SEARCH_METHOD.BY_PARTD && (
            <>
              <Form.Item
              name={SEARCH_FORM_FIELDS.PartDCode}
              label={
                <span>
                  Medicare Plan ID{" "}
                  <button 
                    type="button" 
                    onClick={() => setIsInstructionsModalVisible(true)} 
                    className="link-button" 
                    style={{ 
                      padding: 0, 
                      backgroundColor: 'transparent', 
                      border: 'none', 
                      color: '#007bff', 
                      textDecoration: 'underline', 
                      cursor: 'pointer', // Make it clear that this is clickable
                    }}
                    onMouseEnter={(e) => e.target.style.textDecoration = 'none'}  // Remove underline on hover
                    onMouseLeave={(e) => e.target.style.textDecoration = 'underline'}  // Restore underline on mouse out
                  >
                    (Example)
                  </button>
                </span>
              }
              rules={[{ required: true, message: 'Please enter Medicare Plan ID.' }]}
            >
            <StatusSelect
            options = {planOptions}
            onSearch={debouncedSearchPlans}
            onChange={handlePlanChange}
            showContactModal={showContactModal}
            />
          </Form.Item>
            <Modal
              title="How to Find Medicare Plan ID"
              visible={isInstructionsModalVisible}
              onCancel={handleInstructionsModalCancel}
              footer={[
                <Button key="back" onClick={handleInstructionsModalCancel}>
                  Close
                </Button>,
              ]}
            >
              <ul>
                <li>Check insurance card for a code starts with a letter (H, R, or S) followed by a series of 7 or 10 digits.</li>
                <li>Example codes might look like <strong>H6158-001</strong> or <strong>H6158-001-002</strong>.</li>
                <li>If you cannot find the code, <button type="button" onClick={showContactModal} className="link-button" style={{ padding: 0, backgroundColor: 'transparent', border: 'none', color: '#007bff', textDecoration: 'underline' }}>contact us</button> and we will help you find it</li>
                <img src="https://i.imgur.com/ovIpEVp.jpeg" alt="Example PartD Code" style={{ maxWidth: '100%', marginTop: '20px' }} />
              </ul>
            </Modal>
            <Modal
              title="Contact Us for Help"
              visible={isContactModalVisible}
              onCancel={handleContactModalCancel}
              footer={null}
            >
              <Form onFinish={handleContactModalSubmitPartD}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input placeholder="Your Name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                >
                  <Input placeholder="Your Email" />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: 'Please provide insurance details or patient info.' }]}
                >
                  <Input.TextArea 
                    placeholder="Provide any information you have about the patient's insurance, and any issues you met using the platform."
                    rows={4}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            </>
          )}
          {searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE && (
            <>
              <FormSuggestInput
              name={SEARCH_FORM_FIELDS.CountyCode}
              label="County Name"
              onChange={planOptionsDepsChangeCallback}
              onSuggest={(suggestion) => suggestion}
              rules={[{ required: true, message: 'Please enter a county name.' }]}
            />
              <Form.Item
                name={SEARCH_FORM_FIELDS.Plan}
                label="Insurance Plan"
                rules={[{ required: true, message: 'Please select an insurance plan.' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handlePlanChange}
                >
                  {planOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}


            <Form.Item
              label="Diabetes Drug Class"
              name={SEARCH_FORM_FIELDS.DrugClass}
              rules={[{ required: true, message: 'Please select a drug class.' }]}
            >
              <Select 
                    options={drugClassSelectOptions} 
                    onChange={handleDrugClassChange}
                />
            </Form.Item>
            {selectedDrugClass === 'insulin' && (
                <Alert
                    message="Important Note"
                    description="All covered insulin products have a maximum copay of $35 per 30-day supply."
                    type="info"
                    showIcon
                    style={{ marginBottom: '20px' }}
                />
            )}
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Search
            </Button>
          </Form>
          
          {selectedPlanDetails && (
            <div>
            <p><strong>Searched Plan Name: {selectedPlanDetails.plan_name}</strong></p>
            <div><strong>Plan Annual Deductible:</strong> ${typeof deductible === 'number' ? deductible.toFixed(2) : ''}</div>
          </div>
          )}
          <DrugClassResultsTableNew
            results={results}
            selectedInsurancePlan={selectedPlanDetails.formulary_id}
            selectedPlanDetails={selectedPlanDetails}
            expandedRow={expandedRow}
            setExpandedRow={setExpandedRow}
            handlePopupOpen
          />
          {showInsulinAlert && (
                <Alert
                    message="Insulin Alert"
                    description="Insulin detected in results. Max copay $35 per 30-day supply."
                    type="warning"
                    showIcon
                />
            )}
        </>
      );
    }