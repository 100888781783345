import React from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';

function DrugSpecificInfo({ drugInfo }) {
  // You can structure this component however you need, using the drugInfo prop
  return (
    <div className="drugSpecificInfo">
      <h2>{drugInfo.title}</h2>
      {/* You can loop through the drugInfo object to display its properties */}
      {/* For simplicity, I'm just displaying a few fields */}
      <p><strong>MEDICATION(S):</strong> {drugInfo.medications.join(', ')}</p>
      <p><strong>PA INDICATION INDICATOR:</strong> {drugInfo.paIndicationIndicator}</p>
      <p><strong>REQUIRED MEDICAL INFORMATION:</strong> {drugInfo.requiredMedicalInfo}</p>
      {/* Add more fields as necessary */}
    </div>
  );
};

export default function PriorAuthorization() {
  const location = useLocation();
  console.log(location); // This will show the entire location object
  console.log(location.state); 
  // const navigate = useNavigate();

  // const handleGoToPriorAuth = () => {
  //   navigate('/prior-authorization', {
  //     state: {
  //       drugName: 'Ozempic',
  //       insurancePlan: 'Blue Shield Rx Enhanced (PDP)'
  //     }
  //   });
  // };
  const { drugName, insurancePlan } = location.state || {}; // Default to an empty object
  const drugDetails = {
    title: "SEMAGLUTIDE SQ (OZEMPIC)",
    medications: ["OZEMPIC (0.25 OR 0.5 MG/DOSE)", "OZEMPIC (1 MG/DOSE)", "OZEMPIC (2 MG/DOSE)"],
    paIndicationIndicator: "1 - All FDA-Approved Indications",
    requiredMedicalInfo: "Type 2 diabetes: Confirmed by one of the following tests: Hemoglobin AIC of 6.5% or higher, or fasting blood sugar (glucose) of at least 126 mg/dl, or blood glucose of greater than or equal to 200 mg/dl either by Oral Glucose Tolerance Test or Plasma (blood) test AND one of the following: trial and failure or side effect with metformin or medical reason why metformin cannot be used, or AIC of 7.5% or greater and being used with another diabetes drug, or patient has heart (cardiovascular) disease.",
    ageRestriction: "N/A",
    prescriberRestriction:'N/A',
    coverageDuration:'Plan year',
    otherCriteria:'Dose and duration is not more than the FDA labeled maximum.'
    // Add other fields as necessary
  };

  // Determine if we should display the drug specific information
  const shouldDisplayDrugInfo = drugName === "Ozempic" && insurancePlan === "Blue Shield Rx Enhanced (PDP)";

  return (
    <div className="priorAuthPage">
      <h1>Prior Authorization</h1>
      {/* Conditionally render the DrugSpecificInfo component */}
      {shouldDisplayDrugInfo && <DrugSpecificInfo drugInfo={drugDetails} />}
      <p><strong>MEDICATION(S):</strong></p>
      <p>OZEMPIC (0.25 OR 0.5 MG/DOSE), OZEMPIC (1 MG/DOSE), OZEMPIC (2 MG/DOSE)</p>
      <p> <strong>PA INDICATION INDICATOR:</strong> 1 - All FDA-Approved Indications </p>
      <p><strong>OFF LABEL USES:</strong> N/A </p>
      <p><strong>EXCLUSION CRITERIA:</strong>Being used with another GLP-1 agent </p>
      <p><strong>REQUIRED MEDICAL INFORMATION</strong> </p>
      <p>Type 2 diabetes: Confirmed by one of the following tests: </p>
      <p>Hemoglobulin A1C of 6.5% or higher, or fasting blood sugar (glucose) of at least 126 mg/dl , or blood glucose of greater than or equal to 200 mg/dl either by Oral Glucose Tolerance Test or Plasma (blood) test </p>
      <p>AND one of the following: trial and failure or side effect with metformin or medical reason why metformin cannot be used, or A1C of 7.5% or greater and being used with another diabetes drug, or patient has heart (cardiovascular) disease. </p>
      <p><strong>AGE RESTRICTION:</strong> N/A </p>
      <p><strong>PRESCRIBER RESTRICTION:</strong> N/A </p>
      <p><strong>COVERAGE DURATION:</strong> Plan year </p>
      <p><strong>OTHER CRITERIA:</strong> Dose and duration is not more than the FDA labeled maximum.</p>
      <p><strong>Choose one of the following methods to proceed with prior authorization:</strong></p>
      
      <div className="optionsContainer">
        {/* Option 1: Download Form */}
        <div className="optionBox">
          <h2>Download Form</h2>
          <p>Download the necessary forms to begin your prior authorization offline.</p>
          <button className="downloadButton">Download Form</button>
        </div>
        
        {/* Option 2: Fill Online */}
        <div className="optionBox">
          <h2>Fill Online</h2>
          <p>Complete your prior authorization online with our guided form.</p>
          <button className="fillOnlineButton">Fill Online</button>
        </div>
      </div>
    </div>
  );
}
