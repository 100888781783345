import { Button, Form, Table } from 'antd';
import React, { useState } from 'react';
import { api, useAPI } from '../../api';
import FormSuggestInput from '../../components/FormSuggestInput';

// const initialValues4TestOnly = {
//   plan: 'Blue Cross Medicare Advantage Health Choice (PPO)',
//   county: 'Clay',
//   drug: 'Pancreas DB Liquescence',
// };

const resultTableColumns = [
  {
    dataIndex: 'proprietaryname',
    title: 'Proprietary Name',
  },
  {
    dataIndex: 'active_numerator_strength',
    title: 'Strength',
  },
  {
    dataIndex: 'active_ingred_unit',
    title: 'Unit',
  },
  {
    dataIndex: 'packagedescription',
    title: 'Description',
  },
  {
    dataIndex: 'tier_level_value',
    title: 'Tier Level Value',
    render(_, result) {
      return result.covered ? result.tier_level_value : 'Not Covered';
    },
  },
  {
    dataIndex: 'requirements',
    title: 'Coverage Requirements',
    render(requirements) {
      return requirements.join('; ');
    },
  },
  {
    dataIndex: 'location_identifier',
    title: 'State/Region',
  },
];

export default function SingleDrugSearch() {
  const [results, setResults] = useState([]);
  const searchDrugInfo = useAPI(
    api.searchDrugInfo,
    // set this option to `true` if you wanna handle loading and error manually
    // when `true`
    //   1. the api will not pop up loading message automatically, and
    //   2. you'll need to handle response error
    false,
  );

  async function handleCheckCoverage(values) {
    try {
      const result = await searchDrugInfo(values.county, values.drug, values.plan);
      setResults(result);
    } catch (ex) {
      // if `silent` is set to `true`
      // deal with the error here
    }
  }

  return (
    <>
      <Form
        layout="vertical"
        style={{ maxWidth: 600 }}
        initialValues={undefined}
        onFinish={handleCheckCoverage}
      >
        <FormSuggestInput
          label="Insurance Plan"
          name="plan"
          rules={[{ required: true, message: 'Please enter an insurance plan name.' }]}
          inputAsOption={
            // set the value to `true` if user's input can be accepted as valid option
            false
          }
        />
        <FormSuggestInput
          label="County Name"
          name="county"
          rules={[{ required: true, message: 'Please enter a county name.' }]}
        />
        <FormSuggestInput
          label="Drug Name"
          name="drug"
          rules={[{ required: true, message: 'Please enter a drug name.' }]}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">Check Coverage</Button>
        </Form.Item>
      </Form>
      <Table columns={resultTableColumns} dataSource={results} />
    </>
  );
}
