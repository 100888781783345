import './index.css';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table, Modal, Button } from 'antd';
import React, { useState } from 'react';
import DrugCoverageSummary from '../../components/DrugCoverageSummary';
import {
  SupplyDurationSelector,
  PharmacyTypeSelector,
  groupDrugsByNonproprietaryName,
  getCustomTierSortOrder,
  sortByTier,
  resultTableColumns,
  proprietaryNameDetailColumns,
  getProprietaryNameExpandedRows
} from '../../components/DrugClassResultsTableHelpers';

const prepareData = (data) => {
  return data.map((item, index) => ({
    ...item,
    uniqueId: `${item.proprietaryname}-${item.nonproprietaryname}-${index}`
  }));
};

const PlanDetailsTable = ({ planDetails, results = [], supplyDuration, pharmacyType, handlePopupOpen, expandedRow, toggleDetails }) => {
  console.log('PlanDetailsTable props:', { planDetails, results, supplyDuration, pharmacyType, expandedRow });
  const hasTierSix = results.some(drug => drug.tier_level_value === 6);
  const sortOrder = getCustomTierSortOrder(hasTierSix);
  const groupedDrugResults = groupDrugsByNonproprietaryName(results);
  const sortedGroupedDrugResults = sortByTier(groupedDrugResults, sortOrder);
  const preparedData = prepareData(sortedGroupedDrugResults);
  

  return (
    <div className="plan-results">
      <table className="plan-details-table">
        <thead>
          <tr>
            <th colSpan="4">Searched Plan Name: {planDetails.plan_name} (Formulary:{planDetails.formulary_id})</th>
          </tr>
          <tr>
            <th colSpan="4">Plan Annual Deductible: ${planDetails.deductible.toFixed(2)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4">
              <Table
                columns={resultTableColumns(supplyDuration, pharmacyType, handlePopupOpen, planDetails.formulary_id, planDetails)}
                dataSource={preparedData}
                rowKey="uniqueId" // Ensure unique ID is used here
                expandable={{
                  expandedRowKeys: expandedRow ? [expandedRow] : [],
                  onExpand: (expanded, record) => {
                    console.log('Row expanded:', { expanded, record });
                    const nameKey = record.uniqueId; // Use uniqueId
                    toggleDetails(nameKey);
                  },
                  expandedRowRender: record => {
                    const drugs = getProprietaryNameExpandedRows(record.nonproprietaryname, sortedGroupedDrugResults);
                    return (
                      <>
                        <Table
                          columns={proprietaryNameDetailColumns(planDetails.formulary_id)}
                          dataSource={drugs}
                          pagination={false}
                          showHeader={true}
                        />
                      </>
                    );
                  },
                  expandIcon: ({ expanded, onExpand, record }) => {
                    const shouldShowIcon = record.nonproprietaryname;
                    if (shouldShowIcon) {
                      return expanded ? (
                        <CaretUpOutlined style={{ fontSize: '20px' }} onClick={e => { e.stopPropagation(); onExpand(record, e); }} />
                      ) : (
                        <CaretDownOutlined style={{ fontSize: '20px' }} onClick={e => { e.stopPropagation(); onExpand(record, e); }} />
                      );
                    }
                    return null;
                  },
                }}
                pagination={false}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


const DrugClassResultsPage = ({ resultsByPlan }) => {
  console.log('DrugClassResultsPage props:', { resultsByPlan });
  const [expandedRow, setExpandedRow] = useState(null);
  const [supplyDuration, setSupplyDuration] = useState('30days');
  const [pharmacyType, setPharmacyType] = useState('retail');
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  const handlePopupClose = () => setVisiblePopup(false);

  const handlePopupOpen = (drugName, content) => {
    console.log('Opening popup for:', { drugName, content });
    setPopupContent(content);
    setPopupTitle(`Requirement Details for ${drugName}`);
    setVisiblePopup(true);
  };

  const handleSupplyDurationChange = (newValue) => {
    console.log('Supply duration changed to:', newValue);
    setSupplyDuration(newValue);
  };

  const handlePharmacyTypeChange = (newValue) => {
    console.log('Pharmacy type changed to:', newValue);
    setPharmacyType(newValue);
  };

  const toggleDetails = (nameKey) => {
    const newExpandedRow = expandedRow === nameKey ? null : nameKey;
    console.log('Toggling details for:', nameKey);
    setExpandedRow(newExpandedRow);
  };

  const planKeys = Object.keys(resultsByPlan);
  const isSinglePlan = planKeys.length === 1;

  return (
    <div>
      <SupplyDurationSelector value={supplyDuration} onChange={handleSupplyDurationChange} />
      <PharmacyTypeSelector value={pharmacyType} onChange={handlePharmacyTypeChange} />

      {isSinglePlan ? (
        planKeys.map(planKey => {
          const planDetails = resultsByPlan[planKey].planDetails;
          const planResults = resultsByPlan[planKey].results || [];
          return (
            <div key={planKey} className="plan-results">
              <DrugCoverageSummary planDetails={planDetails} drugResults={planResults} />
              <PlanDetailsTable
                planDetails={planDetails}
                results={planResults}
                supplyDuration={supplyDuration}
                pharmacyType={pharmacyType}
                handlePopupOpen={handlePopupOpen}
                expandedRow={expandedRow}
                toggleDetails={toggleDetails}
              />
            </div>
          );
        })
      ) : (
        planKeys.map(planKey => {
          const planDetails = resultsByPlan[planKey].planDetails;
          const planResults = resultsByPlan[planKey].results || [];
          return (
            <div key={planKey} className="plan-results">
              <DrugCoverageSummary planDetails={planDetails} drugResults={planResults} />
              <PlanDetailsTable
                planDetails={planDetails}
                results={planResults}
                supplyDuration={supplyDuration}
                pharmacyType={pharmacyType}
                handlePopupOpen={handlePopupOpen}
                expandedRow={expandedRow}
                toggleDetails={toggleDetails}
              />
            </div>
          );
        })
      )}

      <Modal
        title={popupTitle}
        visible={visiblePopup}
        onOk={handlePopupClose}
        onCancel={handlePopupClose}
        footer={[
          <Button key="submit" type="primary" onClick={handlePopupClose}>
            OK
          </Button>
        ]}
      >
        <p dangerouslySetInnerHTML={{ __html: popupContent }}></p>
      </Modal>
    </div>
  );
};

export default DrugClassResultsPage;
