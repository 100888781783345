import { request } from './shared';

export function getInsurancePlans(countyCode, includeMA, includePDP) {
  return request({
    method: 'GET',
    params: {
      county_code: countyCode,
      include_ma: includeMA,
      include_pdp: includePDP,
    },
    silent: true,
    url: '/get-insurance-plans',
  });
}
