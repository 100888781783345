import { request } from './shared';

export function sendContactInfo(values) {
  return request({
    data: {
      name: values.name,
      email: values.email,
      message: values.message
    },
    method: 'POST',
    url: '/send-contact',
  });
}
