import { request } from './shared';

export function searchPlansByName(query) {
  return request({
    method: 'GET',
    params: {
      query: query, 
    },
    silent: true,
    url: '/search-utah-plans',
  });
}