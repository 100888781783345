import { Form, Select } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { api, useAPI } from '../../api';

function textToOption(value) {
  return {
    label: value,
    value,
  };
}

/**
 * @param {Boolean} props.inputAsOption accept user input as a valid option
 * @param {String} props.value value
 * @param {String} props.placeholder placeholder when value is empty 
 * @param {String} props.field the field for suggestion query
 * @param {String} props.name property of Form.Item. alternative to `props.field` if `props.field` is absent
 * @param {String} props.label property of Form.Item
 * @param {Object} props.rules property of Form.Item
 */
export default function FormSuggestInput(props) {
  const [suggestions, setSuggestions] = useState([]);
  const timerRef = useRef();
  const [value, setValue] = useState(props.value || '');
  const suggest = useAPI(api.suggest);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  async function handleSearch(query) {
    if (props.inputAsOption) {
      setSuggestions([textToOption(query)]);
    }
    const currenttimer = timerRef.value = (timerRef.value || 0) + 1;
    if (query.length < 3) {
      return;
    }
    await new Promise(resolve => setTimeout(resolve, 500));
    if (currenttimer !== timerRef.value) {
      return;
    }
    suggest(props.field || props.name, query).then((values) => {
      let options = [];
      if (props.inputAsOption) {
        options.push(textToOption(query));
        const uniqueLabels = new Set(options.map(option => option.label.toLowerCase()));
        for (const { label } of values) {
          if (!uniqueLabels.has(label.toLowerCase())) {
            options.push(textToOption(label));
            uniqueLabels.add(label.toLowerCase());
          }
        }
      } else {
        values = values.filter(item => item.label); 
        const uniqueValues = Array.from(new Set(values.map(item => item.label.toLowerCase())))
          .map(uniqueLabel => {
            return values.find(item => item.label.toLowerCase() === uniqueLabel);
          });
        options = uniqueValues.map(value => props.onSuggest ? props.onSuggest(value) : textToOption(value.label));
      }
      setSuggestions(options);
    });
  }

  function handleChange(value, option) {
    setValue(value);
    if (props.onChange) {
      props.onChange(value, option);
    }
  }

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      rules={props.rules}
    >
      <Select
        showSearch
        allowClear
        value={value}
        placeholder={props.placeholder}
        defaultActiveFirstOption={false}
        suffixIcon={props.inputAsOption ? null : undefined}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        options={suggestions}
      />
    </Form.Item>
  );
}
